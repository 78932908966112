import React from 'react'
import styled from '@emotion/styled'

import { breakpoints } from '../styles/variables'

const StyledDistillerText = styled.div`
  font-feature-settings: 'palt';
  line-height: 2;
  padding: 0 2rem;

  dl {
    display: flex;
    flex-wrap: wrap;
    margin: 2em 0;
  }

  dt {
    color: var(--color-figure-0500);
    font-size: var(--font-size-s1);
    width: 7rem;
  }

  dd {
    margin: 0;
    width: calc(100% - 8rem);
  }

  a {
    border-bottom: 1px solid var(--color-figure-0250);
    color: inherit;
    padding-bottom: 0.2em;
    text-decoration: none;
    transition: border-bottom-color 0.25s;

    &:active {
      border-bottom-color: var(--color-figure-1000);
    }

    @media (hover: hover) {
      &:hover {
        border-bottom-color: var(--color-figure-1000);
      }
    }
  }

  @media (min-width: ${breakpoints.tablet}px) {
    margin: 0 auto;
    max-width: 52rem;
    padding: 0 8rem;

    dt {
      width: 14rem;
    }

    dd {
      width: calc(100% - 15rem);
    }
  }
`

export const DistillerText: React.FC = ({ children }) => (
  <StyledDistillerText>{children}</StyledDistillerText>
)
