import React from 'react'
import { FluidObject } from 'gatsby-image'
import { graphql } from 'gatsby'

import { AppPageProps } from '../types'
import { DistillerPageQuery } from '../../types/graphql-types'
import { DistillerText } from '../components/DistillerText'
import { Layout } from '../components/Layout'
import { LoadingContext } from '../contexts/LoadingContext'
import { OpticalImage } from '../components/OpticalImage'
import { SectionList } from '../components/SectionList'

export const query = graphql`
  query DistillerPage {
    heroMobile: file(relativePath: { eq: "images/distiller-hero@mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heroTablet: file(relativePath: { eq: "images/distiller-hero@tablet.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

interface DistillerPageProps extends AppPageProps {
  data: DistillerPageQuery
}

const DistillerPage: React.FC<DistillerPageProps> = ({
  pathContext: { langKey },
  data: { heroMobile, heroTablet },
}) => (
  <Layout
    langKey={langKey}
    pageTitle="会社情報"
    titleSublabel="会社情報"
    titleLabel="Distiller"
  >
    <SectionList>
      <LoadingContext.Provider value="eager">
        <OpticalImage
          fluidMobile={heroMobile?.childImageSharp?.fluid as FluidObject}
          fluidTablet={heroTablet?.childImageSharp?.fluid as FluidObject}
          sizes="100vw"
        />
      </LoadingContext.Provider>
      <DistillerText>
        <dl>
          <dt>代表取締役</dt>
          <dd>黒木 信作</dd>
          <dt>創業</dt>
          <dd>1998年</dd>
          <dt>電話</dt>
          <dd>
            <a href="tel:0983223973">0983223973</a>
          </dd>
          <dt>メールアドレス</dt>
          <dd>
            <a href="mailto:info@osuzuyama.co.jp">info@osuzuyama.co.jp</a>
          </dd>
          <dt>所在地</dt>
          <dd>
            <a
              href="https://goo.gl/maps/mSZHJSQGje82"
              target="_blank"
              rel="noopener noreferrer"
            >
              〒884-0104 宮崎県児湯郡木城町石河内字倉谷656番17
            </a>
          </dd>
        </dl>
      </DistillerText>
    </SectionList>
  </Layout>
)

export default DistillerPage
