import React from 'react'
import styled from '@emotion/styled'

import { breakpoints } from '../styles/variables'

const StyledSectionList = styled.div`
  > div {
    margin-bottom: 8rem;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    > div {
      margin-bottom: 10rem;
    }
  }
`

export const SectionList: React.FC = ({ children }) => (
  <StyledSectionList>
    {React.Children.map(children, (child) => (
      <div>{child}</div>
    ))}
  </StyledSectionList>
)
