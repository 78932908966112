import React, { useRef, useEffect, useReducer, useContext } from 'react'
import { FluidObject } from 'gatsby-image'
import styled from '@emotion/styled'

import { breakpoints } from '../styles/variables'
import { LoadingContext } from '../contexts/LoadingContext'

const StyledOpticalImage = styled.div`
  position: relative;

  &::before {
    content: '';
    display: block;
    padding-top: 150%;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    &::before {
      padding-top: 50%;
    }
  }
`

const Picture = styled.picture`
  img {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

const FocusedPicture = styled(Picture)<{ loaded: boolean }>`
  img {
    opacity: ${({ loaded }) => (loaded ? 1 : 0)};
    transition: opacity 1s;
  }
`

interface OpticalImageProps {
  fluidMobile: FluidObject
  fluidTablet: FluidObject
  className?: string
  sizes?: string
}

export const OpticalImage: React.FC<OpticalImageProps> = ({
  fluidMobile,
  fluidTablet,
  className,
  sizes,
}) => {
  const [loaded, markLoaded] = useReducer(() => true, false)
  const imgRef = useRef<HTMLImageElement>(null)
  useEffect(() => {
    if (imgRef.current?.complete) markLoaded()
  }, [])
  const loading = useContext(LoadingContext)

  return (
    <StyledOpticalImage className={className}>
      <Picture>
        <source
          srcSet={fluidTablet.base64}
          media={`(min-width: ${breakpoints.tablet}px)`}
        />
        <source srcSet={fluidMobile.base64} />
        <img src={fluidTablet.base64} alt="" />
      </Picture>
      <FocusedPicture loaded={loaded}>
        <source
          type="image/webp"
          srcSet={fluidTablet.srcSetWebp}
          media={`(min-width: ${breakpoints.tablet}px)`}
          sizes={sizes}
        />
        <source
          srcSet={fluidTablet.srcSet}
          media={`(min-width: ${breakpoints.tablet}px)`}
          sizes={sizes}
        />
        <source
          type="image/webp"
          srcSet={fluidMobile.srcSetWebp}
          sizes={sizes}
        />
        <source srcSet={fluidMobile.srcSet} sizes={sizes} />
        <img
          src={fluidTablet.src}
          alt=""
          onLoad={() => markLoaded()}
          ref={imgRef}
          loading={loading}
        />
      </FocusedPicture>
    </StyledOpticalImage>
  )
}
